<template>
  <header class="wrapper">
    <IdentityCard :user="creator" />
    <div class="actions">
      <ClientOnly>
        <NuxtLink
          v-if="!override"
          class="date"
          :to="'/' + creator.username + '/posts/' + postId"
        >
          {{ publishedDate }}
        </NuxtLink>

        <UPopover overlay :ui="popperStyles">
          <font-awesome-icon
            icon="fa-solid fa-ellipsis"
            class="text-[#ACABAB] dark:text-[#4B575D]"
          />

          <template #panel="{ close }">
            <DropdownMenuList :items="menuItems" />
          </template>
        </UPopover>

        <!--
        <UPopover
          v-if="showContextMenu"
          :popper="{ placement: 'auto' }"
          :ui="menuStyles"
        >
          <Button theme="ghost" :iconOnly="true" size="xs">
            <font-awesome-icon
              icon="fa-light fa-ellipsis"
              size="xl"
              class="text-[#595858] dark:text-[#555F63]"
            />
          </Button>
          <template #panel>
            <div class="contextMenu">
              <Button
                v-if="!owned"
                theme="ghost"
                shape="square"
                @click="handlePostReported(postId)"
              >
                <span class="menuLabel">
                  <font-awesome-icon
                    icon="fa-light fa-flag"
                    class="fa-fw pink"
                  />
                  Report Post
                </span>
              </Button>
              <Button
                v-if="owned"
                theme="ghost"
                shape="square"
                @click="handlePostEdit(postId)"
              >
                <span class="menuLabel">
                  <font-awesome-icon
                    icon="fa-light fa-edit"
                    class="fa-fw pink"
                  />
                  Edit Post
                </span>
              </Button>
              <Button
                v-if="owned"
                theme="ghost"
                shape="square"
                @click="deleteConfirmationShowing = true"
              >
                <span class="menuLabel">
                  <font-awesome-icon
                    icon="fa-light fa-trash"
                    class="fa-fw pink"
                  />
                  Delete Post
                </span>
              </Button>
            </div>
          </template>
        </UPopover>
        -->

        <!-- Delete Post: Confirmation Modal -->
        <UModal
          v-model="deleteConfirmationShowing"
          :ui="{ container: 'items-start md:items-center' }"
        >
          <ConfirmModal
            title="Delete Post"
            @cancel="deleteConfirmationShowing = false"
            @confirm="handlePostDelete(postId)"
            @close="deleteConfirmationShowing = false"
          >
            <div class="flex flex-col gap-2 p-4">
              <span>Are you sure you want to delete this post?</span>
            </div>
          </ConfirmModal>
        </UModal>
      </ClientOnly>
    </div>
  </header>
</template>

<script setup>
  import { useUserStore } from "@/store/user"

  const userStore = useUserStore()
  const config = useRuntimeConfig()
  const dayjs = useDayjs()

  const props = defineProps({
    postId: {
      type: String,
      required: true,
    },
    creator: {
      type: Object,
      required: true,
    },
    contextMenu: {
      type: Boolean,
      default: false,
    },
    override: {
      type: Boolean,
      default: false,
    },
    createdAt: {
      type: String,
      required: true,
    },
    deletable: {
      type: Boolean,
      default: true,
    },
  })

  const deleteConfirmationShowing = ref(false)

  const emit = defineEmits(["post-deleted", "post-reported", "post-edit"])

  const owned = computed(() => {
    return props.creator.username == userStore.username
  })

  const showContextMenu = computed(() => {
    return owned.value && props.contextMenu
  })

  const publishedDate = computed(() => {
    return dayjs(new Date(props.createdAt)).format("MMM D")
  })

  const menuStyles = {
    background: "dark:bg-[#2B3236]",
  }

  const handlePostReported = (id) => {
    emit("post-reported", id)
  }

  const handlePostDelete = async (id) => {
    if (!id) return
    const toast = useToast()
    try {
      $api(`${config.public.API_URL}/api/posts/${id}`, { method: "DELETE" })
      deleteConfirmationShowing.value = false
      toast.add({ title: "Post deleted.", color: "green" })
      emit("post-deleted", id)
    } catch (err) {
      deleteConfirmationShowing.value = false
      toast.add({ title: "Failed to delete post.", color: "red" })
    }
  }

  const handlePostEdit = (id) => {
    emit("post-edit", id)
  }

  const menuItems = computed(() => {
    let items = []

    if (owned.value) {
      items.push({
        label: "Edit Post",
        icon: "fa-light fa-edit",
        click: () => handlePostEdit(props.postId),
      })

      if (props.deletable) {
        items.push({
          label: "Delete Post",
          icon: "fa-light fa-trash",
          click: () => (deleteConfirmationShowing.value = true),
        })
      }
    } else {
      items.push({
        label: "Report Post",
        icon: "fa-light fa-flag",
        click: () => handlePostReported(props.postId),
      })
    }

    return [items]
  })

  const popperStyles = {
    overlay: {
      base: "fixed inset-0 transition-opacity z-50",
      background: "bg-gray-200/25 dark:bg-gray-800/25",
      transition: {
        enterActiveClass: "ease-out duration-200",
        enterFromClass: "opacity-0",
        enterToClass: "opacity-100",
        leaveActiveClass: "ease-in duration-150",
        leaveFromClass: "opacity-100",
        leaveToClass: "opacity-0",
      },
    },
    rounded: "rounded-md",
  }
</script>

<style lang="scss" scoped>
  .wrapper {
    @apply flex flex-row items-center justify-between;
  }

  .actions {
    @apply flex flex-row items-center justify-end ml-auto gap-6;
  }

  .date {
    @apply text-xs whitespace-nowrap text-[#595858] dark:text-[#555F63];
  }

  .contextMenu {
    @apply flex flex-col gap-2 p-2;
  }

  .menuLabel {
    @apply flex flex-row items-center justify-start gap-2;
    @apply text-[#595858] dark:text-white dark:hover:text-white/75;
  }

  .pink {
    @apply text-[#F2699B] dark:text-[#F2699B];
  }
</style>
