<template>
  <div :id="comment.id" class="flex flex-row justify-between items-start gap-3">
    <!-- Avatar -->
    <Avatar
      :src="comment.author.avatarUrl"
      size="md"
      class="cursor-pointer mr-2"
      @click="handleIdentityClick(comment.author.username)"
    />

    <div class="grow flex flex-col items-start justify-center mb-4">
      <!-- Author Displayname -->
      <div
        class="flex flex-row items-center cursor-pointer"
        @click="handleIdentityClick(comment.author.username)"
      >
        <span class="font-medium text-sm">
          {{ comment.author.display_name }}
        </span>
        <font-awesome-icon
          v-if="comment.author.approved_creator"
          icon="fa-solid fa-badge-check"
          class="text-pink-600 ml-1"
        />
        <span class="text-xs ml-4 text-[#595858] dark:text-[#555F63]">
          {{ $dayjs(comment.createdAt).fromNow().replace("hours", "h") }}
        </span>
      </div>

      <!-- Author Username -->
      <div
        class="flex items-center text-[#595858] dark:text-[#555F63] cursor-pointer"
        @click="handleIdentityClick(comment.author.username)"
      >
        <span class="text-xs">@{{ comment.author.username }}</span>
      </div>

      <!-- Body -->
      <div class="flex items-center mt-2">
        <p
          ref="body"
          class="text-sm text-pretty leading-snug tracking-wide"
          v-html="highlightMentions(comment.text)"
        />
      </div>

      <!-- Actions -->
      <div
        class="w-full flex flex-row text-xs mt-2 text-[#595858] dark:text-[#555F63]"
      >
        <div>{{ comment.totalLikes }} Likes</div>
        <div class="mx-1.5">•</div>
        <div
          class="cursor-pointer hover:text-pink-500"
          @click="toggleReplyForm"
        >
          Reply
        </div>
        <!--         
        <div v-if="isCommentOwner(comment.author)" class="mx-1.5">•</div>
        <div v-if="isCommentOwner(comment.author)" class="cursor-pointer hover:text-pink-500">Edit</div> 
        -->
        <div v-if="isCommentOwner(comment.author)" class="mx-1.5">•</div>
        <div
          v-if="isCommentOwner(comment.author)"
          class="cursor-pointer hover:text-pink-500"
          @click="showConfirm = true"
        >
          Delete
        </div>
        <!-- <div v-if="comment?.replies.length" class="text-right mr-6 cursor-pointer hover:text-pink-500" @click="toggleShowReplies">{{ showReplies ? 'Hide ' : 'Show ' }} {{ comment.replies.length }} Replies</div> -->
      </div>

      <!-- Replies -->
      <div v-if="showReplies" class="animate-fade-left mt-4 w-full">
        <PostCommentReply
          v-for="reply in comment?.replies"
          :key="reply.id"
          :reply="reply"
          @reply-deleted="emit('comment-deleted', reply.id)"
          @reply-liked="emit('comment-liked', reply.id)"
          @reply-unliked="emit('comment-unliked', reply.id)"
          @reply-complaint="emit('comment-complaint', reply.id)"
        />
      </div>

      <!-- Reply Form -->
      <div v-if="showReplyForm" class="w-full animate-fade-down">
        <PostCommentReplyForm
          :postId="comment.postId"
          :parentId="comment.id"
          @reply-created="handleCommentReply"
        />
      </div>
    </div>

    <!-- Reaction -->
    <div class="mt-1" @click="toggleLike">
      <font-awesome-icon
        v-if="liked"
        icon="fa-solid fa-heart"
        size="lg"
        class="hover-pink"
      />
      <font-awesome-icon
        v-else
        icon="fa-light fa-heart"
        size="lg"
        class="hover-pink"
      />
    </div>
  </div>

  <!-- Delete Comment: Confirmation Modal -->
  <UModal
    v-model="showConfirm"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <ConfirmModal
      title="Delete Comment"
      @cancel="showConfirm = false"
      @confirm="handleDelete"
      @close="showConfirm = false"
    >
      <div class="flex flex-col gap-2 p-4">
        <span>Are you sure you want to delete this comment?</span>
      </div>
    </ConfirmModal>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    comment: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits([
    "comment-liked",
    "comment-unliked",
    "comment-deleted",
    "comment-reply-added",
    "comment-complaint",
  ])

  const config = useRuntimeConfig()
  const userStore = useUserStore()
  const toast = useToast()
  const showReplyForm = ref(false)
  const showReplies = ref(true)
  const liked = ref(props.comment.likedByUser || false)
  const showConfirm = ref(false)

  const highlightMentions = (text) => {
    const regex = /\B@\w+/g
    return text.replace(regex, function (match) {
      let username = match.trim().substring(1)
      return `<span class="text-pink-500">${match}</span>`
    })
  }

  const isCommentOwner = (author) => {
    return author.username === userStore.username
  }

  const toggleReplyForm = () => {
    return (showReplyForm.value = !showReplyForm.value)
  }

  const toggleShowReplies = () => {
    showReplies.value = !showReplies.value
    showReplyForm.value = showReplies.value ? true : false
  }

  const toggleLike = async () => {
    if (!userStore.loggedIn)
      navigateTo({ path: "/login", query: { redirectBackTo: route.fullPath } })
    liked.value = !liked.value

    try {
      await $api(
        `${config.public.API_URL}/api/posts/${props.comment.postId}/comments/${props.comment.id}/like`,
        { method: liked.value ? "POST" : "DELETE" },
      )

      if (liked.value) {
        useShootHearts()
        emit("comment-liked", props.comment.id)
      } else {
        emit("comment-unliked", props.comment.id)
      }
    } catch (err) {
      console.log(err)
      toast.add({
        title: "Like comment error",
        description: "An error occurred while liking the comment",
        type: "error",
      })
    }
  }

  const handleIdentityClick = (username) => {
    console.log("Identity clicked")
    navigateTo({ path: `/${username}` })
  }

  const handleCommentReply = (reply) => {
    emit("comment-reply-added", reply)
  }

  const handleEdit = () => {
    console.log("Comment edited")
  }

  const handleDelete = async () => {
    try {
      await $api(
        `${config.public.API_URL}/api/posts/${props.comment.postId}/comments/${props.comment.id}`,
        { method: "DELETE" },
      )
      showConfirm.value = false
      toast.add({ title: "Comment Deleted", type: "success" })
      emit("comment-deleted", props.comment.id)
    } catch (err) {
      console.error(err)
      toast.add({ title: "Error Deleting Comment", type: "error" })
    }
  }

  const handleReply = () => {
    console.log("Comment replied")
  }

  const handleShare = () => {
    console.log("Comment shared")
  }

  const handleComplaint = () => {
    console.log("Comment reported")
  }
</script>
