<template>
  <div :id="reply.id" class="flex flex-row justify-between items-start gap-3">
    <!-- Avatar -->
    <Avatar
      :src="reply.author.avatarUrl"
      size="md"
      class="cursor-pointer mr-2"
      @click="handleIdentityClick(reply.author.username)"
    />

    <div class="grow flex flex-col items-start justify-center mb-4">
      <!-- Author Displayname -->
      <div
        class="flex flex-row items-center cursor-pointer"
        @click="handleIdentityClick(reply.author.username)"
      >
        <span class="font-medium text-sm">{{ reply.author.displayName }}</span>
        <font-awesome-icon
          v-if="reply.author.isCreator"
          icon="fa-solid fa-badge-check"
          class="text-pink-600 ml-1"
        />
        <span class="text-xs ml-4 text-[#595858] dark:text-[#555F63]">
          {{ $dayjs(reply.created_at).fromNow().replace("hours", "h") }}
        </span>
      </div>

      <!-- Author Username -->
      <div
        class="flex items-center text-[#595858] dark:text-[#555F63] cursor-pointer"
        @click="handleIdentityClick(reply.author.username)"
      >
        <span class="text-xs">@{{ reply.author.username }}</span>
      </div>

      <!-- Body -->
      <div class="flex items-center mt-2">
        <p
          ref="body"
          class="text-cool-500 dark:text-cool-400 text-sm text-pretty leading-snug tracking-wide"
          v-html="highlightMentions(reply.text)"
        />
      </div>

      <!-- Actions -->
      <div
        v-if="isReplyOwner(reply.author)"
        class="w-full flex flex-row text-xs mt-2 text-[#595858] dark:text-[#555F63]"
      >
        <!--
          <div class="cursor-pointer hover:text-pink-500" @click="handleReplyEdit">Edit</div>
          <div v-if="isReplyOwner(reply.author)" class="mx-1.5">•</div>
        -->
        <div
          v-if="isReplyOwner(reply.author)"
          class="cursor-pointer hover:text-pink-500"
          @click="showConfirm = true"
        >
          Delete
        </div>
      </div>
    </div>

    <!-- Reaction -->
    <div class="mt-1" @click="toggleLike">
      <font-awesome-icon
        v-if="liked"
        icon="fa-solid fa-heart"
        size="lg"
        class="hover-pink"
      />
      <font-awesome-icon
        v-else
        icon="fa-light fa-heart"
        size="lg"
        class="hover-pink"
      />
    </div>
  </div>

  <!-- Delete Reply: Confirmation Modal -->
  <UModal
    v-model="showConfirm"
    :ui="{ container: 'items-start md:items-center' }"
  >
    <ConfirmModal
      title="Delete Comment Reply"
      @cancel="showConfirm = false"
      @confirm="handleDelete"
      @close="showConfirm = false"
    >
      <div class="flex flex-col gap-2 p-4">
        <span>Are you sure you want to delete your reply?</span>
      </div>
    </ConfirmModal>
  </UModal>
</template>

<script setup>
  const props = defineProps({
    reply: {
      type: Object,
      required: true,
    },
  })

  const emit = defineEmits([
    "reply-liked",
    "reply-unliked",
    "reply-deleted",
    "reply-complaint",
  ])

  const userStore = useUserStore()
  const config = useRuntimeConfig()
  const toast = useToast()
  const liked = ref(props.reply.liked_by_user || false)
  const showConfirm = ref(false)

  const isReplyOwner = (author) => {
    return author.username === userStore.username
  }

  const highlightMentions = (text) => {
    const regex = /\B@\w+/g
    return text.replace(regex, function (match) {
      let username = match.trim().substring(1)
      return `<a href="/${username}" class="text-pink-500">${match}</a>`
    })
  }

  const toggleLike = async () => {
    if (!userStore.loggedIn)
      navigateTo({ path: "/login", query: { redirectBackTo: route.fullPath } })
    liked.value = !liked.value

    try {
      await $api(
        `${config.public.API_URL}/api/posts/${props.reply.post_id}/comments/${props.reply.id}/like`,
        { method: liked.value ? "POST" : "DELETE" },
      )

      if (liked.value) {
        useShootHearts()
        emit("reply-liked", props.reply.id)
      } else {
        emit("reply-unliked", props.reply.id)
      }
    } catch (err) {
      console.log(err)
      toast.add({
        title: "Like reply error",
        description: "An error occurred while liking the reply",
        type: "error",
      })
    }
  }

  const handleIdentityClick = (username) => {
    navigateTo({ path: `/${username}` })
  }

  const handleDelete = async () => {
    try {
      await $api(
        `${config.public.API_URL}/api/posts/${props.reply.post_id}/comments/${props.reply.id}`,
        { method: "DELETE" },
      )
      showConfirm.value = false
      toast.add({ title: "Your reply has been removed", type: "success" })
      emit("reply-deleted", props.reply.id)
    } catch (err) {
      console.error(err)
      toast.add({ title: "Error deleting comment reply", type: "error" })
    }
  }

  const handleReplyEdit = async () => {
    console.log("Edit reply")
  }
</script>
